import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import Form from "../components/Form";
import Icon, { IconType } from "../components/Icons";
import {
  circleSmall,
  contactIllustration,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const ContactContent = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const RowContact = styled(Row)`
  align-items: flex-start;
  position: relative;
  ${[theme.media.maxM]} {
    display: block;
  }
`;

const RowAddress = styled(Row)`
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 60px;
  ${[theme.media.maxS]} {
    margin-left: -15px;
    margin-right: -15px;
    & > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

const ColAddress = styled.div`
  ${[theme.media.maxS]} {
    max-width: 50%;
    flex-basis: 50%;
  }
`;

const HeadingAddress = styled.h4`
  margin-bottom: 0;
`;

const ContactParagraph = styled.p`
  margin-bottom: 50px;
`;

const Illustration = styled.div`
  img {
    display: block;
    margin-left: auto;
    max-width: calc(100% + 140px);
    width: calc(100% + 140px);
    margin-right: -280px;
    ${[theme.media.maxM]} {
      width: 80%;
      margin-right: 0;
    }
  }

  ${[theme.media.maxM]} {
    position: absolute;
    top: -150px;
    right: 0px;
    width: 250px;
    z-index: -1;
  }
  ${[theme.media.maxS]} {
    right: -40px;
    width: 200px;
  }
`;

const LinkWithIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: ${theme.font.proxima};
  font-weight: 400;
  margin-bottom: 2px;
  strong {
    font-weight: 600;
  }
`;

const Link = styled.a`
  color: ${theme.colors.grayDark};
  text-decoration: none;
  margin-left: 25px;
  &:hover {
    text-decoration: underline;
  }
`;

class ContactPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.contact.title1" }) + " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.contact.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.contact.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.contact.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <ContactContent>
            <Container>
              <RowContact spacing={15}>
                <Col6>
                  <h1>
                    <FormattedMessage id="pages.contact.title1" />
                  </h1>
                  <ContactParagraph>
                    <FormattedMessage id="pages.contact.text1" />
                  </ContactParagraph>
                  <LinkWithIcon>
                    <Icon width={28} height={28} icon={IconType.Email} />
                    <Link href="mailto:eledio@eledio.com">
                      <strong>eledio@eledio.com</strong>
                    </Link>
                  </LinkWithIcon>
                  <RowAddress spacing={30}>
                    <ColAddress>
                      <HeadingAddress>
                        <FormattedMessage id="pages.contact.title2" />
                      </HeadingAddress>
                      <p>
                        <FormattedHTMLMessage id="pages.contact.text2" />
                      </p>
                    </ColAddress>
                    <ColAddress>
                      <HeadingAddress>
                        <FormattedMessage id="pages.contact.title3" />
                      </HeadingAddress>
                      <p>
                        <FormattedHTMLMessage id="pages.contact.text3" />
                      </p>
                    </ColAddress>
                  </RowAddress>
                  <h3>
                    <FormattedMessage id="pages.contact.title4" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.contact.text4" />
                  </p>
                  <Form secondary="true" />
                </Col6>
                <Col6>
                  <Illustration>
                    <img src={contactIllustration} alt="Contact illustration" />
                  </Illustration>
                </Col6>
              </RowContact>
            </Container>
          </ContactContent>
        </Layout>
      </>
    );
  }
}

export default injectIntl(ContactPage);
